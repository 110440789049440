<template>
    <div>
        <div class="back" v-if="show">
            <div class="relative" style="width: 100%;height: 156px;">
				<img src="https://wwh-web.oss-cn-beijing.aliyuncs.com/phoneRateImg/img-fldhzx.png"
					style="width: 100%;position: absolute;z-index: 1;top: 0;left: 0;object-fit:cover;" mode="">
				
			</div>
            <div class="wid_95 mg-auto   relative shadowViw" style="overflow: visible;background-color:#fff;border-radius: 0 10px 10px 10px;">
				<div class=" flex_dom centerO absolute " style="top: -35px;z-index: 111;">
					<div class="p-l-10 bg-FF1  color-fff p-r-10    relative font32" style="height:35px;line-height: 35px;border-radius: 10px 10px 0 0;" v-for="(item,index) in 1"
						:key="index" 
						@click="changeType(item.coupon_id)">
						会员福利{{index+1}}
					</div>
					
				
				</div>
				<div class="pad20">
					<div class="m-t-10">
						<div class="flex-dom-wrap" style="align-items: center;">
							<div class=" text-center " style="width: 33%;" v-for="(item,index) in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]">
								<div class="relative m-t-10 "  @click="showMo()">
									<img src="https://wwh-web.oss-cn-beijing.aliyuncs.com/phoneRateImg/hongbao.png"  style="width: 90%;">
									
									<div class="absolute wid_100" style="top: 1rem;" >
										
										<div class=" font26 text-center color-red m-t-45">
											第{{index+1}}月领取
										</div>
										
									</div>
								
								</div>
							</div>
						</div>
					</div>
				</div>
                <van-popup  v-model:show="showLogin" round :style="{ padding: '30px' }">
                    <div style="font-size: 20px;font-weight: bold;">提示!</div>
                    <div style="font-size: 16px;margin-top: 10px;">
                        为了你的账号安全检测到您还未登录请先验证您的手机后再进行领取
                    </div>
                    <div class="bottmBtn" @click="dowond_wx()">
                        前往登录领取
                    </div>
                </van-popup>
			</div>
        </div>
        <div v-else style="height: 100vh;display: flex;flex-direction: column;justify-content: center;">
            <div>
                <div style="text-align: center;">
                    <img src="https://wwh-web.oss-cn-beijing.aliyuncs.com/duanju_img/order_detail.jpg" alt=""
                        style="width: 3rem;">
                </div>
                <van-loading size="36px" type="spinner" color="rgb(0, 140, 255)" vertical>

                    <div>订单加载中...</div>
                    <div>

                    </div>
                </van-loading>

                <div style="width: 80%; margin: auto;margin-top: 10px;text-align: center;padding: 15px 0;border-radius: 30px;font-size: .3rem;background-color: #4e51fe;color: #fff;font-weight: bold;"
                    @click="openBack">
                    不等了(返回上一页{{ 4 - num <= 0 ? '' : 4 - num }}) </div>
                </div>


            </div>
        </div>
</template>

<script>
import axios from "axios";
import { showToast, showLoadingToast, closeToast } from "vant";
export default {
    data() {
        return {
            showLogin:false,
            show: false,
            order_id: '',
            channel_id: '',
            pay_type: '',
            pay_price: '',
            callback_url: '',
            num: 0,

        };
    },

    mounted() {
        const { order_id, channel_id, channel_no, pay_type, pay_price, callback_url, } = this.$route.query
        this.order_id = order_id||this.$route.query['amp;order_id']
        this.channel_id = channel_id||this.$route.query['amp;channel_id']
        this.pay_price = pay_price||this.$route.query['amp;pay_price']
        this.pay_type = pay_type||this.$route.query['amp;pay_type']
        this.callback_url = callback_url||this.$route.query['amp;callback_url']
        if (this.order_id) {
            this.loopOrderState({
                order_id: this.order_id,
                channel_id: this.channel_id
            })

        }
    },
    methods: {
        showMo(){
            this.showLogin=true
        },
        //前往微信
        dowond_wx() {
             window.location.href='https://qwe.shwbzyhwbf.xyz/'

        },

        openBack() {
            if (4 - this.num <= 0) {
                this.callback();
            }
        },
        getQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2]);
            }

            return null;
        },
        loopOrderState(datas) {
            //轮询当前订单状态s
            let that = this;
            that.num = 0;
            let data = datas

            that.T = setInterval(() => {
                axios
                    .post(`${window.location.origin}/api/v1/live/recharge/coupon/query`, data)
                    .then((res) => {
                        if (res.data.code == 0 && res.data.data.is_pay == 1) {
                            clearInterval(that.T);
                            that.show = true
                            // window.location.href='https://qwe.shwbzyhwbf.xyz/'
                        } else {
                            that.num++;
                            if (that.num > 9) {
                                that.callback();
                            }
                        }
                    })
                    .catch(() => {
                        that.num++;
                        if (that.num > 9) {
                            that.callback();
                        }
                    });
            }, 1000);
        },
        callback() {
            clearInterval(this.T);
            showToast("订单未支付");
            setTimeout(() => {
                window.location.href = decodeURIComponent(atob(this.callback_url));
            }, 500);
        },
        advGo() {
            window.location.href = "https://u.tuiabcedc.cn/2c5a";
        },
        goWxKefu() {
            window.location.href =
                "https://work.weixin.qq.com/kfid/kfc1fbe95a001b15c7d";
        },
    },
};
</script>

<style lang="less" scoped>
@keyframes subumitScale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(.86);
    }

    100% {
        transform: scale(1);
    }
}

.back {
    width: 100%;
    height: 100vh;

    .backImg {
        width: 100%;
    }

    .adv {
        position: absolute;
        top: 13.68rem;
        text-align: center;
        animation: subumitScale 1s linear alternate infinite;

        // z-index: 10;
        .widHig {
            width: 8.2rem;
        }
    }

    .succ {
        position: absolute;
        top: 16.2rem;
        text-align: center;

        // z-index: 10;
        .widHigSucc {
            width: 8.2rem;
        }
    }
}

.downLoad {
    >div {
        width: 4.53rem;
        height: 1.17rem;
        border: 1px solid #1B1B1B;
        border-radius: 0.59rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.31rem 0.77rem;
        box-sizing: border-box;
        font-size: 0.37rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #022222;

    }

    .downLoad_i {
        margin-top: .3rem;
    }

}
.relative{
    position: relative;
}
.wid_95{
    width: 95%;
}
.mg-auto{
    margin: auto;
}
.shadowViw{
    box-shadow: 0rpx 0rpx 8px  #bcbcbc;
}
.flex_dom{display:flex!important;display: -webkit-box!important;display: -webkit-flex!important;}
.centerO{align-items: center;}
.absolute{position: absolute;}
.p-l-10{ padding-left:5px;}
.p-r-10{ padding-right:5px;}
.bg-ccc{background-color: #cccccc!important;}
.font32{font-size: 16px!important;}
.bg-FF1{background-color: #FF172A !important;}
.color-fff{color:#fff !important;}
.pad20{padding: 10px;}
.m-t-10{margin-top: 5px;}
.flex-dom-wrap{display:flex;display:-webkit-flex;justify-content:flex-start;-webkit-justify-content:flex-start;flex-wrap:wrap;-webkit-flex-wrap:wrap;}
.text-center{text-align: center;}
.wid_100{width:100%!important;overflow-x: hidden;}
.color-red{
    color: #FF172A;
}
.bottmBtn{
    background-color: #FF172A;
    color: #fff;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    border-radius: 25px;
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
}
</style>