<template>
  <div>

    <div>
      <div class="shouyintai" v-if="showB">
        <div class="title">订单收银台</div>

        <div class="img">
          <div class="parice">支付金额：￥{{ phoneBillData.price ? phoneBillData.price : 0 }}</div>

        </div>
        <div class="contentTR">
          <div>支付方式</div>
          <div class="selectType">
            <div class="box">
              <div class="selectItem">
                <div class="box_flex">
                  <img src="https://wwh-web.oss-cn-beijing.aliyuncs.com/huawei_2/ali.png" alt=""
                    style="width: 0.47rem" />
                  <img src="https://wwh-web.oss-cn-beijing.aliyuncs.com/huawei_2/wx.png" alt=""
                    style="width: 0.47rem;margin-left: .1rem;" />
                  <span style="font-weight: 700; color: rgba(0, 0, 0, 0.9)">在线支付</span>
                  <!-- <span style="color: red">(首单随机立减，最高至免单)</span> -->
                </div>
                <div>
                  <img src="https://wwh-web.oss-cn-beijing.aliyuncs.com/huawei_2/select_in.png" alt=""
                    style="width: 20px" />
                </div>
              </div>


            </div>
          </div>
          <div class="button">
            <div class="btn" @click="getPayment">确认支付</div>
          </div>
        </div>
        <div class="contentTR">
          <div>支付注意事项：</div>
          <div class="remark">
            1.支付成功后台请返回到该页面，系统将为你自动跑匹配商品支付的成功页面。<br>2.如若支付异常请勿重复提交订单支付,多次提交的用户将进行24小时封禁。
            <br>3.如出现多次拉起未支付的用户将在本平台降低支付信誉分,请您按照需求进行下单切勿出现拉起不支付的情况。
          </div>
        </div>
      </div>
    </div>
    <van-popup :show="showMeol" :style="{ padding: '0.6rem', borderRadius: '.5rem', width: '90%' }">
      <div class="font32">提示！</div>
      <div class="m-t-20">缺少订单信息</div>

    </van-popup>
    <div class="view" v-show="false">
      <div class="hearder">
        订单支付
        <img class="img" @click="back" src="../assets/img/back_icon.png" alt="" />
      </div>
      <div class="content">
        <div style="color: #aaaaaa; font-weight: 400; font-size: 0.35rem">
          需支付金额
        </div>
        <div style="
            color: #333333;
            font-weight: 400;
            font-size: 0.74rem;
            margin: 0.4rem 0 0.53rem;
          ">
          <span style="font-size: 0.43rem">¥</span> {{ pay_price }}
        </div>
        <div style="color: #333333; font-weight: 400; font-size: 0.35rem">
          商品价格：{{ pay_price }}
        </div>
      </div>
      <div class="button">
        <div class="btn" @click="getPayment">确认支付</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { showToast } from "vant";
export default {
  data() {
    return {
      goods_id: "",
      channel_id: "",
      pay_price: "0",
      phone: "",
      pay_type: "",
      pay_mode: "",
      adv_m_id: "",
      adv_client_id: "",
      adv_client_appid: "",
      channel_no: "",
      coupon_id: "",
      payIcon: "",
      buttonChange: false,
      platfrom: "",
      openid: '',
      subTrue: true,
      order_url_c: '',
      payVideo: '',
      phoneBillData: {
        price: 0
      },
      showMeol: false,
      showB: true,
      pay_client: ''
    };
  },
  created() {
    if (this.isAlipayClient()) {
      if (this.getQueryString("goods_id")) {
        sessionStorage.setItem('goods_id', this.getQueryString("goods_id"))
      }
      if (this.getQueryString("payIcon")) {
        sessionStorage.setItem('payIcon', this.getQueryString("payIcon"))
      }
      if (this.getQueryString("channel_id")) {
        sessionStorage.setItem('channel_id', this.getQueryString("channel_id"))
      }
      if (this.getQueryString("phone")) {
        sessionStorage.setItem('phone', this.getQueryString("phone"))
      }
      if (this.getQueryString("pay_type")) {
        sessionStorage.setItem('pay_type', this.getQueryString("pay_type"))
      }
      if (this.getQueryString("channel_id")) {
        sessionStorage.setItem('channel_id', this.getQueryString("channel_id"))
      }
      if (this.getQueryString("pay_mode")) {
        sessionStorage.setItem('pay_mode', this.getQueryString("pay_mode"))
      }
      if (this.getQueryString("adv_client_id")) {
        sessionStorage.setItem('adv_client_id', this.getQueryString("adv_client_id"))
      }
      if (this.getQueryString("adv_client_appid")) {
        sessionStorage.setItem('adv_client_appid', this.getQueryString("adv_client_appid"))
      }
      if (this.getQueryString("callback_url")) {
        sessionStorage.setItem('callback_url', this.getQueryString("callback_url"))
      }
      if (this.getQueryString("channel_no")) {
        sessionStorage.setItem('channel_no', this.getQueryString("channel_no"))
      }
      if (this.getQueryString("coupon_id")) {
        sessionStorage.setItem('coupon_id', this.getQueryString("coupon_id"))
      }
      if (this.getQueryString("platfrom")) {
        sessionStorage.setItem('platfrom', this.getQueryString("platfrom"))
      }
      if (this.getQueryString("payVideo")) {
        sessionStorage.setItem('payVideo', this.getQueryString("payVideo"))
      }
    }
    this.goods_id = this.getQueryString("goods_id");
    this.payIcon = this.getQueryString("payIcon");
    this.channel_id = this.getQueryString("channel_id");
    this.pay_price = this.getQueryString("pay_price");
    this.phone = this.getQueryString("phone");
    this.pay_type = this.getQueryString("pay_type");
    this.pay_mode = this.getQueryString("pay_mode");
    this.adv_m_id =
      this.getQueryString("adv_m_id") == "undefined" ||
        !this.getQueryString("adv_m_id")
        ? ""
        : this.getQueryString("adv_m_id");
    if (this.getQueryString("adv_m_id") == "undefined" ||
      !this.getQueryString("adv_m_id")) {

    } else {
      sessionStorage.setItem('adv_m_id', this.getQueryString("adv_m_id"))
    }

    this.adv_client_id = this.getQueryString("adv_client_id");
    this.adv_client_appid = this.getQueryString("adv_client_appid");
    this.callback_url = this.getQueryString("callback_url");
    this.channel_no = this.getQueryString("channel_no");
    this.coupon_id = this.getQueryString("coupon_id");
    this.platfrom = this.getQueryString("platfrom");
    this.payVideo = this.getQueryString("payVideo")
    this.pay_client = this.getQueryString("pay_client")
    if ((this.payVideo == 1) || (this.payVideo == 2)) {
      this.showB = false
    } else {
      this.getBoxLists();
    }
    if (this.getQueryString("user_id")) {
      this.openid = this.getQueryString("user_id")
      this.getAlipayInfo()
      this.getPayment();
    }

  },
  mounted() {

    //畅连支付监听页面进入后台并且是点击按钮后进入后台的
    document.addEventListener("visibilitychange", () => {
      if (
        this.buttonChange &&
        this.getQueryString("return_url") &&
        !document.hidden
      ) {
        console.log(504);

        this.buttonChange = false;
        if (this.getQueryString("return_url")) {
          window.location.href = unescape(this.getQueryString("return_url"));
        }
        if (this.isBaiDu()) {
          window.location.href = unescape(this.order_url_c);
        }
        window.location.href = unescape(this.getQueryString("return_url"));
      }
    });
    //真对短剧
    if (this.getQueryString("order_id")) {
      this.loopOrderState();
    } else {
      if (!this.isAlipayClient()) {
        this.getPayment();
        if (this.payVideo == 1 ||
          this.payVideo == 2) {
          this.getPayment();
        }
      }
    }
  },
  methods: {
    getBoxLists() {
      if (this.payVideo == 'phoneBill') {
        axios
          .get(`${window.location.origin}/api/v1/live/recharge/coupon/detail?g_id=${this.getQueryString("goods_id")}`)
          .then((res) => {
            console.log(res)
            if (res.data.data) {
              this.phoneBillData = res.data.data
            } else {
              this.showMeol = true
            }
          });
      }
      else {
        axios.get(`${window.location.origin}/api/v1/box/index?box_id=${this.getQueryString("goods_id")}`).then(res => {

          if (res.data.code == 0) {
            this.phoneBillData.price = res.data.data.box.price
          } else {
            this.showMeol = true
          }
        })
      }

    },
    getAlipayInfo() {
      if (this.isAlipayClient()) {
        if (sessionStorage.getItem("goods_id")) {
          this.goods_id = sessionStorage.getItem("goods_id")
        }
        if (sessionStorage.getItem("payIcon")) {
          this.payIcon = sessionStorage.getItem("payIcon")
        }
        if (sessionStorage.getItem("channel_id")) {
          this.channel_id = sessionStorage.getItem("channel_id")
        }
        if (sessionStorage.getItem("phone")) {
          this.phone = sessionStorage.getItem("phone")
        }
        if (sessionStorage.getItem("pay_type")) {
          this.pay_type = sessionStorage.getItem("pay_type")
        }
        if (sessionStorage.getItem("channel_id")) {
          this.channel_id = sessionStorage.getItem("channel_id")
        }
        if (sessionStorage.getItem("pay_mode")) {
          this.pay_mode = sessionStorage.getItem("pay_mode")
        }
        if (sessionStorage.getItem("adv_client_id")) {
          this.adv_client_id = sessionStorage.getItem("adv_client_id")
        }
        if (sessionStorage.getItem("adv_client_appid")) {
          this.adv_client_appid = sessionStorage.getItem("adv_client_appid")
        }
        if (sessionStorage.getItem("callback_url")) {
          this.callback_url = sessionStorage.getItem("callback_url")
        }
        if (sessionStorage.getItem("channel_no")) {
          this.channel_no = sessionStorage.getItem("channel_no")
        }
        if (sessionStorage.getItem("coupon_id")) {
          this.coupon_id = sessionStorage.getItem("coupon_id")
        }
        if (sessionStorage.getItem("platfrom")) {
          this.platfrom = sessionStorage.getItem("platfrom")
        }
        if (sessionStorage.getItem("payVideo")) {
          this.payVideo = sessionStorage.getItem("payVideo")
        }
      }
    },
    isAlipayClient() {

      if (navigator.userAgent.indexOf('AlipayClient') > -1) {
        return true

      }

      return false

    },
    isAli() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/AlipayClient/i) == "alipayclient") {
        return true
      }
      return false
    },
    isBaiDu() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (sUserAgent.indexOf('baidu') > 0) {
        return true
      }
      return false
    },
    loopOrderState() {
      //轮询当前订单状态s
      let that = this;
      let num = 0;
      let data = {
        order_id: this.getQueryString("order_id"),
        channel_id: this.getQueryString("channel_id"),
      };
      that.T = setInterval(() => {
        axios
          .post(`${window.location.origin}/api/v1/video/payOrderSuccess`, data)
          .then((res) => {
            if (res.data.code == 0) {
              clearInterval(that.T);
              window.location.href =
                "https://work.weixin.qq.com/kfid/kfc1fbe95a001b15c7d";
            } else {
              num++;
              if (num > 9) {
                that.callback();
              }
            }
          })
          .catch(() => {
            clearInterval(that.T);
            showToast("网络错误，请稍后重试");
            this.showsuccess_pay = false;
          });
      }, 1000);
    },
    callback() {
      clearInterval(this.T);
      showToast("订单未支付");
      setTimeout(() => {
        window.location.href = decodeURIComponent(atob(this.callback_url));
      }, 500);
    },
    paySucc() {
      let data = {
        order_id: this.getQueryString("order_id"),
        channel_id: this.getQueryString("channel_id"),
      };
      axios
        .post(`${window.location.origin}/api/v1/video/payOrderSuccess`, data)
        .then((res) => {
          if (res.data.code == 0) {
            window.location.href =
              "https://work.weixin.qq.com/kfid/kfc1fbe95a001b15c7d";
          }
        })
        .catch((err) => {
          showToast("未查询到已支付订单");
        });
    },
    back() {
      // window.history.go(-1);
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }

      return null;
    },
    encode(str) {
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
        )
      );
    },
    updateQueryStringParameter(uri, key, value) {
      if (!value) {
        return uri;
      }
      uri = uri.split('#/').join("");
      var re = new RegExp("(^|&)" + key + "=([^&]*)(&|$)", "i");
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";
      if (uri.indexOf(key) !== -1) {
        return uri + "#/";
      }
      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2") + "#/";
      } else {
        return uri + separator + key + "=" + value + "#/";
      }
    },
    phoneOs() {
      var u = navigator.userAgent,
        isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1,
        isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      if (isAndroid) {
        return "Android";
      }

      if (isiOS) {
        return "IOS";
      }
      return "";
    },
    getPayment() {
      let that = this;
      if (!this.subTrue) {
        return
      }
      if (this.subTrue) {
        this.subTrue = false
      }
      const {
        pay_price,
        phone,
        pay_type,
        goods_id,
        pay_mode,
        adv_m_id,
        adv_client_id,
        channel_id,
        callback_url,
        channel_no,
        coupon_id,
        payIcon,
        platfrom,
        openid,
        pay_client
      } = this;
      if (
        this.payVideo == 1 ||
        this.payVideo == 2
      ) {
        let datas = {
          pay_price: 59,
          pay_type: pay_type,
          channel_no: channel_no,
          channel_id: channel_id,
          coupon_id,
          goods_id: this.payVideo,
          callback_url:
            callback_url + `/?channel_no=${channel_no}&coupon_id=1#/`,
          pay_mode: 1,
          goods_name: "手机",
          return_url: window.location.origin + "/#/vedioSuccess",
          adv_m_id,
          adv_client_id,
          openid
        };
        axios
          .post(`${window.location.origin}/api/v1/video/submit`, datas)
          .then((res) => {
            let order_url = escape(res.data.data.return_url);

            if (res.data.code == 0) {
              this.subTrue = true
              if (payIcon == 2) {
                let arr = [
                  { key: "coupon_id", value: 1 },
                  { key: "payIcon", value: payIcon },
                  { key: "order_id", value: res.data.data.order_id },
                  { key: "channel_id", value: res.data.data.channel_id },
                ];
                arr.forEach((item) => {
                  let newurl = that.updateQueryStringParameter(
                    window.location.href,
                    item.key,
                    item.value
                  );
                  window.history.replaceState(
                    {
                      path: newurl,
                    },
                    "",
                    newurl
                  );
                });
              }
              if (platfrom == "6" || platfrom == "11" || platfrom == "13") {
                //证明用户点击过后才进入后台
                that.buttonChange = true;
                // that.returnUrl=res.data.data.return_url
                let newurl = that.updateQueryStringParameter(
                  window.location.href,
                  "return_url",
                  order_url
                );
                window.history.replaceState(
                  {
                    path: newurl,
                  },
                  "",
                  newurl
                );
              }
              // that.$router.push({path:'/payPage',query: {url: res.data.data.pay_data}})
              if (platfrom == "13") {
                that.iframe_rul = "";
                let url = res.data.data.pay_data.split("//");
                let toUrl = res.data.data.pay_data;
                that.iframe_rul = toUrl.slice(29);
                // url.splice(4,0,'convertPayway')
                let src = url[1].split("/");
                src.splice(3, 0, "convertPayway");
                let sure = src.join("/");
                url = url[0] + "//" + sure;
                axios
                  .post(url, {
                    wayCode: that.payIcon == 2 ? "ALI_WAP" : "WX_H5",
                    payDataType: "",
                  })
                  .then((bizData) => {
                    if (bizData.data.data.payDataType == "payurl") {
                      if (that.payIcon == 2) {
                        if (that.isBaiDu()) {
                          let iframe = document.getElementById("iframe1");
                          const iframeWindow = iframe.contentWindow;
                          // alert(iframeWindow)
                          setTimeout(() => {
                            iframeWindow.document
                              .getElementsByClassName("paycashier-type")[3]
                              .click();
                            iframeWindow.document
                              .getElementsByClassName("confirmBtn")[0]
                              .click();
                          }, 2000);
                        } else {
                          window.location.href = bizData.data.data.payData;
                        }
                      } else {
                        var m =
                          bizData.data.data.payData.match(/wxaurl\.cn\/(\w+)/);
                        if (m && m[1]) {
                          let mockUrlScheme = `weixin://dl/business/?t=${m[1]}`;
                          window.location.href = mockUrlScheme;
                        }
                      }
                    }
                  });
              } else {
                window.location.href = res.data.data.pay_data;
              }
            } else {
              showToast(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            showToast(err);
            this.subTrue = true
          });
      }
      //电费
      else if (this.payVideo == "powerRate") {
        let datas = {
          pay_price: 38.8,
          pay_type: pay_type,
          channel_no: channel_no,
          channel_id: channel_id,
          coupon_id,
          goods_id: goods_id,
          callback_url: callback_url + `/?channel_no=${channel_no}#/`,
          pay_mode: 1,
          goods_name: "手机",
          return_url: window.location.origin + "/#/powerOldSuccess",
          adv_m_id,
          adv_client_id,

        };
        axios
          .post(
            `${window.location.origin}/api/v1/electric/couponSubmitOrder`,
            datas
          )
          .then((res) => {
            if (res.data.code == 0) {
              // that.$router.push({path:'/payPage',query: {url: res.data.data.pay_data}})
              window.location.href = res.data.data.pay_data;
            } else {
              showToast(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            showToast(err);
            this.subTrue = true
          });
      }

      //话费  phoneBill
      else if (this.payVideo == "phoneBill") {
        let datas = {
          pay_price: 38.8,
          pay_type: pay_type,
          channel_no: channel_no,
          channel_id: channel_id,
          coupon_id,
          phone,
          goods_id: goods_id,
          callback_url: callback_url + `/?channel_no=${channel_no}&pay_client=${pay_client}#/`,
          pay_mode: 1,
          goods_name: "话费",
          return_url: window.location.origin + "/#/phoneBillSuccess",
          adv_m_id,
          adv_client_id,
          pay_client: pay_client

        };
        axios
          .post(
            `${window.location.origin}/api/v1/live/recharge/coupon/submit/order`,
            datas
          )
          .then((res) => {
            if (res.data.code == 0) {
              let order_url = escape(res.data.data.return_url)
              that.order_url_c = escape(res.data.data.return_url);
              if (platfrom) {
                //证明用户点击过后才进入后台
                that.buttonChange = true;
                // that.returnUrl=res.data.data.return_url
                let gourl = "";
                if (window.location.href.indexOf("return_url") > -1) {
                  gourl =
                    window.location.href.split("return_url")[0] +
                    "#/" +
                    window.location.href.split("return_url")[1].split("#/")[1];
                }
                let newurl = that.updateQueryStringParameter(
                  gourl ? gourl : window.location.href,
                  "return_url",
                  order_url
                );
                window.history.replaceState(
                  {
                    path: newurl,
                  },
                  "",
                  newurl
                );
              }
              let url;
              if ((payIcon == 2 || payIcon == 5 || payIcon == 6) && platfrom != 31 && platfrom != 1&&platfrom != 33) {

                url = 'alipays://platformapi/startapp?saId=10000007&clientVersion=3.7.0.0718&qrcode=' + encodeURIComponent(res.data.data.pay_data)
                // url = 'alipays://platformapi/startapp?appId=20000067&url=' + encodeURIComponent(res.data.data.pay_data)
                // that.$router.push({path:'/payPage',query: {url: res.data.data.pay_data}})
                if ((platfrom == 21 || platfrom == 30 || platfrom == 1) && that.isBaiDu()) {
                  if (that.phoneOs() && that.phoneOs() == 'Android') {
                    url = 'https://ds.alipay.com/?scheme=' + encodeURIComponent(url)

                  } else if (that.phoneOs() && that.phoneOs() == 'IOS') {
                    url = 'https://render.alipay.com/p/s/i/?scheme=' + encodeURIComponent(url)

                  }

                }

              } else {
                url = res.data.data.pay_data
              }
              window.location.href = url

            } else {
              showToast(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            showToast(err);
            this.subTrue = true
          });
      }
      //盲盒
      else {

        axios
          .post(`${window.location.origin}/api/v1/tui/orderSubmit`, {
            pay_price,
            phone,
            pay_type,
            channel_id,
            coupon_id,
            goods_id,
            callback_url:
              callback_url +
              `/?channel_no=${channel_no}&coupon_id=1&phone=${that.encode(
                phone
              )}#/`,
            pay_mode,
            goods_name: "手机",
            return_url: window.location.origin + "/#/success",
            adv_m_id,
            adv_client_id,
            openid
          })
          .then((res) => {
            let order_url = escape(res.data.data.return_url);
            that.order_url_c = escape(res.data.data.return_url);
            if (res.data.code == 0) {
              this.subTrue = true
              if (platfrom) {
                //证明用户点击过后才进入后台
                that.buttonChange = true;
                // that.returnUrl=res.data.data.return_url
                let newurl = that.updateQueryStringParameter(
                  window.location.href,
                  "return_url",
                  order_url
                );
                window.history.replaceState(
                  {
                    path: newurl,
                  },
                  "",
                  newurl
                );
              }
              // that.$router.push({path:'/payPage',query: {url: res.data.data.pay_data}})
              if (platfrom == "13") {
                let url = res.data.data.pay_data.split("//");
                // url.splice(4,0,'convertPayway')
                let src = url[1].split("/");
                src.splice(3, 0, "convertPayway");
                let sure = src.join("/");
                url = url[0] + "//" + sure;
                axios
                  .post(url, {
                    wayCode: that.payIcon == 2 ? "ALI_WAP" : "WX_H5",
                    payDataType: "",
                  })
                  .then((bizData) => {
                    if (bizData.data.data.payDataType == "payurl") {
                      if (that.payIcon == 2) {
                        window.location.href = bizData.data.data.payData;
                      } else {
                        var m =
                          bizData.data.data.payData.match(/wxaurl\.cn\/(\w+)/);
                        if (m && m[1]) {
                          let mockUrlScheme = `weixin://dl/business/?t=${m[1]}`;
                          window.location.href = mockUrlScheme;
                        }
                      }
                    }
                  });
              } else {
                let url;
                if ((payIcon == 2 || payIcon == 5) && platfrom != 31) {

                  url = 'alipays://platformapi/startapp?saId=10000007&clientVersion=3.7.0.0718&qrcode=' + encodeURIComponent(res.data.data.pay_data)
                  // url = 'alipays://platformapi/startapp?appId=20000067&url=' + encodeURIComponent(res.data.data.pay_data)
                  // that.$router.push({path:'/payPage',query: {url: res.data.data.pay_data}})
                  if ((platfrom == 21 || platfrom == 30) && that.isBaiDu()) {
                    if (that.phoneOs() && that.phoneOs() == 'Android') {
                      url = 'https://ds.alipay.com/?scheme=' + encodeURIComponent(url)

                    } else if (that.phoneOs() && that.phoneOs() == 'IOS') {
                      url = 'https://render.alipay.com/p/s/i/?scheme=' + encodeURIComponent(url)

                    }

                  }

                } else {
                  url = res.data.data.pay_data
                }
                window.location.href = url
              }
            } else {
              console.log(res);
              showToast(res.data.msg);
              setTimeout(() => {
                this.back();
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
            showToast(err);
            this.subTrue = true
            setTimeout(() => {
              this.back();
            }, 3000);
          });
      }
    },
    get_object_first_attribute(data, val) {
      for (var key in data) {
        if (val) {
          return data[key];
        } else {
          return key;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.view {
  width: 100%;
  height: 100vh;
  background: #f7f7f7;
}

.hearder {
  text-align: center;
  font-size: 0.45rem;
  font-weight: 400;
  color: #333333;
  padding: 0.4rem;
  background: #fff;
  position: relative;

  .img {
    width: 0.8rem;
    height: 0.96rem;
    position: absolute;
    left: 0.27rem;
    top: 50%;
    margin-top: -0.48rem;
  }
}

.content {
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 4.8rem;
  width: 100%;
}

.button {
  padding: 0 0.12rem;
  margin-top: 1.29rem;

  .btn {
    width: 100%;
    height: 1.17rem;
    background: linear-gradient(90deg, #3cb6ff 0%, #5dd2fe 100%);
    border-radius: 0.89rem;
    font-size: 0.43rem;
    font-weight: 400;
    color: #fefefe;
    text-align: center;
    line-height: 1.17rem;
  }
}

.shouyintai {
  .title{
      font-size: .4rem;
      text-align: center;
      margin-top: .3rem;
      font-weight: bold;
   }
  .img {
    text-align: center;
    background: linear-gradient(180deg, #3cb6ff 0%, #5dd2fe 100%);
    height: 4rem;
    width: 92%;
    margin: auto;
    margin-top: 1rem;
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }

  .parice {
    font-size: .6rem;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }



  .contentTR {
    width: 92%;
    margin: auto;
    margin-top: 1rem;
    font-size: .4rem;
    box-shadow: inset 0px 0px 16px 0px rgb(233, 233, 233);
    padding: .3rem;
    border-radius: .3rem;
    .remark{
      font-size: .33rem;
      color: #ff4949;
      margin-top: .3rem;
      line-height: .9rem;
    }
  }
}

.selectType {
  margin-top: .6rem;

  .box {}

  .selectItem {
    font-family: PingFang SC;
    font-size: 14px;
    height: 46px;
    background: rgba(252, 56, 72, 0.05);
    border-radius: 25px 25px 25px 25px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    img {}
  }

  .selectItemBorder {
    border-bottom: #e1e1e1 1px solid;
  }
}

.font32 {
  font-size: .5rem;
  font-weight: bold;
}

.m-t-20 {
  margin-top: .3rem;
}
</style>